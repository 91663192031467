import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/screenshots/main-ui.png";
import thumbnailHeading from "../../static/images/screenshots/heading.png";
import thumbnailTasks from "../../static/images/screenshots/tasks.png";
import thumbnailNextEvent from "../../static/images/screenshots/next-event.png";
import thumbnailCalendar from "../../static/images/screenshots/calendar-setting.png";

const IndexPage = () => (
    <Layout>
        <SEO title="Home"/>

        <div className={"page-header home"}>
            <h1>Make your Morning Routine Easier</h1>
            <p>Timely enables you to plan your mornings quickly, so you can take the guesswork out of getting enough sleep.</p>
            <img alt={"Dashboard"} src={featureImage} className={"round-shadow"}/>
        </div>

        <div className={"container"}>
            <div className={"features"}>
                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Heading"} src={thumbnailHeading} className={"round-shadow"}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Calculate Your Sleep Schedule</h2>
                                <p>Configure your optimal sleep duration and "ready time", and Timely calculates what time to set your alarm and to fall asleep.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Easily Adjust Your Morning</h2>
                                <p>Setup your common morning tasks, and quickly enable/disable them to plan tomorrow morning quickly.</p>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Tasks"} src={thumbnailTasks} className={"round-shadow"}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Next Event"} src={thumbnailNextEvent} className={"round-shadow"}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Google Calendar Integration</h2>
                                <p>Timely automatically pulls your first event in the morning to give you a heads up on when you need to be ready.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Calendar Configuration</h2>
                                <p>Fine grained control over which calendars are considered when displaying your first morning event.</p>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Calendar"} src={thumbnailCalendar} className={"round-shadow"}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className={"call-to-action"}>
            <div className={"container"}>
                <div className={"call-to-action__content"}>
                    <h2>Sign up for free</h2>
                    <p>Sign up and start reducing your night time mental load with Timely.</p>
                </div>

                <div className={"button"}>
                    <a href="https://timely-app.drewterry.dev" target={"_blank"}>Get Started</a>
                </div>
            </div>
        </div>
    </Layout>
)

export default IndexPage
